import {
    SET_AUTO_LOGOUT_MUTATION,
    SET_USER_INFO_DATA_MUTATION, SET_USER_ROLES_MUTATION,
    SET_USER_TOKEN_DATA_MUTATION
} from "@/store/storeconstants";

export default {
    [SET_USER_TOKEN_DATA_MUTATION](state, payload){
        state.email = payload.email;
        state.token = payload.token;
        state.userId =  payload.userId;
        state.refreshToken = payload.refreshToken;
        state.expiresIn = payload.expiresIn;
        state.autoLogout = false;
    },

    [SET_USER_INFO_DATA_MUTATION](state, payload){
      state.userInfo = payload;
    },

    [SET_USER_ROLES_MUTATION](state, payload){
      state.userRoles = payload;
    },

    [SET_AUTO_LOGOUT_MUTATION](state){
        state.autoLogout = true;
    }
};