export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привет"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["инструкции"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добавлять"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выполнить"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "add_new_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый запись"])},
  "main_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные информации"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "save_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить копию"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
  "successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно сохранено!"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])}
}