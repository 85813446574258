export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salem"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтасы"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулар"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["орындау"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "add_new_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа жазу енгізу"])},
  "main_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басты ақпарат"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаты"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "save_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көшірмесін сақтау"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәні"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенімдісіз бе?"])},
  "successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақталды!"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағасы"])}
}