import {GET_FORM_CONFIG_GETTER, GET_FORM_PARAMS_GETTER, GET_FORM_RESULTS_GETTER} from "@/store/storeconstants";

export default {
    [GET_FORM_PARAMS_GETTER]: state => {
        return state.params;
    },

    [GET_FORM_RESULTS_GETTER]: state => {
        return state.results;
    },

    [GET_FORM_CONFIG_GETTER]: state => {
        return state.config;
    }

};
