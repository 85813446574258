import axios from "axios";
import {
    FETCH_MENU_ACTION,
    SET_MENU_MUTATION
} from "@/store/storeconstants";

export default {
     async [FETCH_MENU_ACTION](context){
       let response = '';
         try{
             response = await axios.get('forms/navbar/left');
             context.commit(SET_MENU_MUTATION,response.data);
             return response;
         }catch (err) {
             console.log(err);
             return [];
             /*TODO: err handler*/
         }
     }
};
