import axios from "axios";
import {FETCH_HISTORY_ACTION, SET_HISTORY_MUTATION} from "@/store/storeconstants";

export default {
     async [FETCH_HISTORY_ACTION](context){
       let response = '';
         try{
             response = await axios.get('history');
             context.commit(SET_HISTORY_MUTATION,response.data);
             return response;
         }catch (err) {
             console.log(err);
             /*TODO: err handler*/
         }
     },

};