import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
export default {
    namespaced: true,
    state() {
        return {
            params: null,
            config: null,
            results: null
        }
    },
    mutations,
    getters,
    actions
}
