<template>
    <div class="container__main" :class="toggleMenuLeft ?'' : 'grid-class'">
        <div class="left__side">
            <div :style="!toggleMenuLeft?'position: fixed; width: 100px; height: 100vh;  overflow-y: auto;':'position: fixed; width: 271px; height: 100vh;  overflow-y: auto;'">
                <div class="left__side-top" :style="toggleMenuLeft?'':'border-bottom: 0px'" >
                    <ava-box :toggled="toggleMenuLeft"></ava-box>
                </div>
                <div class="left__side-bottom" >
                    <table-menu @click="_addFormActive = false" :toggled="toggleMenuLeft" ></table-menu>
                </div>
            </div>

        </div>
        <div class="right__side">
            <div class="right__side-top" :style="'position: fixed; width: calc(100% - '+(!toggleMenuLeft?100:272)+'px); padding-top: 40px; background-color: white; z-index: 100;'">
                <header-right @toggleMenuLeft="onToggleLeft"></header-right>
            </div>
            <div class="right__side-bottom">
                <div style="padding-top: 16px;">
                    <breadcrumbs></breadcrumbs>
                </div>

                <router-view/>

            </div>
        </div>
        <div class="modal" v-if="showModal">
            <div class="modal__inner">
                Время сессии истекло
            </div>
        </div>

        <!--<div class="modal" v-if="this.$store.state.pdata.loading" style="z-index: 999">
            <loader></loader>
        </div>-->

    </div>


</template>

<script>

import AvaBox from "@/components/common/AvaBox";
import TableMenu from "@/components/common/TableMenu";
import HeaderRight from "@/components/common/HeaderRight";
import Breadcrumbs from "@/components/common/fields/Breadcrumbs";
import {mapActions, mapGetters} from 'vuex';
import {GET_MENU_LEFT, FETCH_USER_INFO_ACTION,GET_USER_INFO_GETTER} from "@/store/storeconstants";
import axios from 'axios';
import Loader from "@/components/common/Loading";
const currentBurgerState = localStorage.getItem('burgerMenu');
export default {
  name: 'MainLayout',
    components: {Loader, Breadcrumbs, HeaderRight, TableMenu, AvaBox},
    data() {
        return {
            toggleMenuLeft: currentBurgerState ? JSON.parse(currentBurgerState) : true,
            showModal: false,
        }
    },
    computed: {
        ...mapGetters('auth', {
            user: GET_USER_INFO_GETTER
        })
    },

    methods: {
        ...mapActions('auth', {
            'userInfo': FETCH_USER_INFO_ACTION,
        }),

        onToggleLeft(t) {
            this.toggleMenuLeft = t;
            localStorage.setItem('burgerMenu', t);
        },

    }

}
</script>
<style scoped lang="scss">
    .container__main {  display: grid;
        grid-template-columns: 272px 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
                "left__side right__side";
        height: 100vh;
        position: relative;
        top: -20px;
    }
    .modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        &__inner {
            background-color: white;
            padding: 50px;
        }
    }
    .grid-class {
        grid-template-columns: 100px 1fr;
    }

    .left__side {  display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 137px 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
                "left__side-top"
                "left__side-bottom";
        grid-area: left__side;
        border-right: 1px solid #D4D3D4;
    }

    .left__side-top {
        grid-area: left__side-top;
        border-bottom: 1px solid #D4D3D4;
        padding: 16px;

    }

    .left__side-bottom {
        grid-area: left__side-bottom;
    }

    .right__side {  display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 71px 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
                "right__side-top"
                "right__side-bottom";
        grid-area: right__side;
        width: 100%;
        position: relative;
    }

    .right__side-top {
        grid-area: right__side-top;
        border-bottom: 1px solid #D4D3D4;


    }

    .right__side-bottom {
        grid-area: right__side-bottom;
        padding-left: 24px;
        padding-top: 20px;

    }
    .right__side-bottom > div{
        padding-bottom: 32px;
    }
</style>

