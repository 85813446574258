/*auth*/
export const LOGIN_ACTION = '[actions] login user';
export const LOGOUT_ACTION = '[actions] logout user';
export const AUTH_ACTION = '[actions] do user auth';
export const AUTO_LOGIN_ACTION = '[actions] auth auto login';
export const AUTO_LOGOUT_ACTION = '[actions] auth auto logout';
export const SET_AUTO_LOGOUT_MUTATION = '[mutations] set auto logout';
export const SET_USER_TOKEN_DATA_MUTATION = '[mutations] set user token';
export const GET_USER_TOKEN_GETTER = '[getters] auth user token';
export const IS_USER_AUTHENTICATE_GETTER = '[getters] is user authenticated';

export const SET_USER_INFO_DATA_MUTATION = '[mutations] set user info';
export const GET_USER_INFO_GETTER = '[getters] auth user info';
export const FETCH_USER_INFO_ACTION = '[action] user info fetch';

export const SET_USER_ROLES_MUTATION = '[mutations] set auth user roles';
export const GET_USER_ROLES_GETTER = '[getters] auth user roles';
export const FETCH_USER_ROLES_ACTION = '[actions] auth user roles fetch';

export const FETCH_USER_PROFILE_ACTION = '[action] fetch user profile';


/** products*/
export const SET_PRODUCTS_MUTATION = '[mutations] set user products';
export const GET_PRODUCTS_GETTER = '[getters] get user products';
export const FETCH_PRODUCTS_ACTION = '[actions] fetch user products';
export const FETCH_ITEM_PRODUCT_ACTION = '[actions] fetch item product';
export const ADD_PRODUCT_ACTION = '[actions] add new product';
export const UPDATE_PRODUCT_ACTION = '[actions] update product';
export const DELETE_PRODUCT_ACTION = '[actions] remote product';

/** categories*/
export const SET_CATEGORIES_MUTATION = '[mutations] set categories';
export const GET_CATEGORIES_GETTER = '[getters] get categories';
export const FETCH_CATEGORIES_ACTION = '[actions] fetch categories';
export const ADD_CATEGORY_ACTION = '[actions] add new category';
export const DELETE_CATEGORY_ACTION = '[actions] remove category';

/** history*/
export const SET_HISTORY_MUTATION = '[mutations] set history';
export const GET_HISTORY_GETTER = '[getters] get history';
export const FETCH_HISTORY_ACTION = '[actions] fetch history';

/** permissions*/
export const SET_PERMISSIONS_MUTATION = '[mutations] set permissions';
export const GET_PERMISSIONS_GETTER = '[getters] get permissions';
export const FETCH_PERMISSIONS_ACTION = '[actions] fetch permissions';
export const FETCH_ITEM_PERMISSION_ACTION = '[actions] fetch item permission';
export const ADD_PERMISSION_ACTION = '[actions] add new permission';
export const UPDATE_PERMISSION_ACTION = '[actions] update permission';
export const ADD_PERMISSION_CATEGORIES = '[actions] add permission categories';
export const DELETE_PERMISSION_ACTION = '[actions] remove permission';

/** roles*/
export const SET_ROLES_MUTATION = '[mutations] set roles';
export const GET_ROLES_GETTER = '[getters] get roles';
export const FETCH_ROLES_ACTION = '[actions] fetch roles';
export const FETCH_ITEM_ROLE_ACTION = '[actions] fetch item role';
export const ADD_ROLE_ACTION = '[actions] add new role';
export const UPDATE_ROLE_ACTION = '[actions] update role';
export const ADD_ROLE_PERMISSIONS_ACTION = '[actions] add role permissions';
export const DELETE_ROLE_ACTION = '[actions] remove role';

/** users*/
export const GET_USERS_ACTION = '[actions] fetch users';
export const ADD_USER_ACTION = '[actions] create user';
export const UPDATE_USER_ACTION = '[actions] update user';
export const DELETE_USER_ACTION = '[actions] delete user';
export const FETCH_ITEM_USER_INFO = '[actions] fetch user info';
export const SET_USERS_MUTATION = '[mutations] set users';
export const ADD_USER_ROLES_ACTION = '[actions] add user roles';
export const UNSET_USER_ROLES_ACTION = '[actions] unset user roles';


/** menu */
export const GET_MENU_GETTER = '[getters] get menu';
export const SET_MENU_MUTATION = '[mutations] set menu';
export const FETCH_MENU_ACTION = '[actions] fetch menu';


/** forms params */
export const FETCH_FORM_PARAMS_ACTION = '[actions] fetch form params';
export const FETCH_FORM_RESULT_ACTION = '[actions] fetch form results';
export const DELETE_FORM_PARAM_ACTION = '[actions] delete form param';
export const GET_FORM_PARAMS_GETTER = '[getters] get form params';
export const GET_FORM_RESULTS_GETTER = '[getters] get form results';
export const SET_FORM_PARAMS_MUTATION = '[mutations] set form params';
export const SET_FORM_RESULTS_MUTATION = '[mutations] set form results';
export const FETCH_FORM_CONFIG_ACTION = '[action] get form config';
export const SET_FORM_CONFIG_MUTATION = '[mutations] set form config';
export const GET_FORM_CONFIG_GETTER = '[getter] get form config'
export const SEND_FORM_PARAMS_ACTION = '[actions] send form params action';
export const FETCH_ITEM_FORM_DATA_ACTION = '[action] fetch item form data action';

/** dictionary */
export const FETCH_DICTIONARY_ACTION = '[actions] fetch dictionary';
export const SET_DICTIONARY_MUTATION = '[mutations] put dictionary data';
export const GET_DICTIONARY_GETTER = '[getters] get dictionary data';

/** forms rows */
export const FETCH_FORM_ROWS_ACTION = '[actions] fetch form rows'
export const GET_FORM_ROWS_GETTER = '[getters] get form rows'
export const SET_FORM_ROWS_MUTATION = '[mutations] set form rows'
export const FETCH_PRODUCTS_HISTORY_ACTION = '[action] fetch receive products';
