import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/services/axios";
import style from '@/assets/css/style.css'
import i18n from '@/services/i18n'
import Camera from "simple-vue-camera"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
/*
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
*/

/* add icons to the library */
library.add(fas)
/*
library.add(fab)
library.add(far)
*/

createApp(App).component("camera", Camera).component('font-awesome-icon', FontAwesomeIcon)
    .use(i18n)
    .use(style)
    .use(store)
    .use(router)
    .mount('#app')
