import axios from "axios";
import {
    ADD_ROLE_ACTION, ADD_ROLE_PERMISSIONS_ACTION,
    DELETE_ROLE_ACTION, FETCH_ITEM_ROLE_ACTION,
    FETCH_ROLES_ACTION,
    SET_ROLES_MUTATION, UPDATE_ROLE_ACTION, UPDATE_USER_ACTION
} from "@/store/storeconstants";

export default {
     async [FETCH_ROLES_ACTION](context){
       let response = '';
         try {
             response = await axios.get('dictionary/roles');
             context.commit(SET_ROLES_MUTATION,response.data);
             return response;
         } catch (err) {
             console.log(err);
             /*TODO: err handler*/
         }
     },

    async [FETCH_ITEM_ROLE_ACTION](context, payload){
        return await axios.get('role/'+payload).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        });
    },

    async [ADD_ROLE_ACTION](context,payload){
        return axios.post('config/access',payload).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    },
    async [UPDATE_ROLE_ACTION](context,payload){
        return axios.put(`role/${payload.id}`,payload).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },
    async [DELETE_ROLE_ACTION](context, payload){
            return await axios.delete('role/'+payload).then(res => {
                return res.data;
            }).catch(err => {
                return Promise.reject(err.response.data);
            });
    },

    async [ADD_ROLE_PERMISSIONS_ACTION](context, payload){
        return axios.post('config/sync-role-permission',payload).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    }
};
