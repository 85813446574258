<template>
    <div style="text-align: center">
        <div class="ava__box" >
            <div class="ava__img">
                <!--<img :src="require('@/assets/img/ava.jpg')" style="position: absolute; bottom: 0; right: calc(50% - 30px)" class="ava__img">-->
                <!--<img v-if="userInfo && userInfo.avatar" style="position: absolute; bottom: 0; right: calc(50% - 30px)" :src="userInfo && userInfo.avatar">-->
            </div>

        </div>
        <div v-if="toggled">
            <div class="name__txt">{{info?.full_name}}</div>
            <div class="post__txt"></div>
        </div>
        <div :style="toggled?'':'width: calc(100%-10px); height: 1px; background-color: #D4D3D4; margin-top: 50px;'" ></div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import {FETCH_USER_PROFILE_ACTION} from "@/store/storeconstants";
    export default {
        name: "AvaBox",
        props:{
            toggled:{
                type: Boolean
            }
        },
        data(){
            return {
                info: localStorage.getItem('userInfoDM')
            }
        },
        created() {
            if (this.info === null){
                this.fetchUserInfo().then(res => {
                    this.info = res;
                })
            }else this.info = JSON.parse(this.info);
        },
        methods: {
            ...mapActions('auth',{
                fetchUserInfo: FETCH_USER_PROFILE_ACTION
            })
        }

    }
</script>

<style scoped lang="scss">
    .ava__box{
        text-align: center;
        cursor: pointer;
        position: relative;
        display: inline-block;
    }
    .ava__img{
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 4px solid #E4EBFA;
        img {
            object-fit: cover;
        }
    }
    .ava__img img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-size: cover;
    }
    .name__txt{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #224084;
    }
    .post__txt{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #224084;
    }
</style>