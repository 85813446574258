export default class Validations {
    static emptyValue(text){
        if(text.length > 0){
            return true;
        }
        return false;
    }
    static checkEmail(email){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return true;
        }
        return false;
    }
    static minLength(name, minLength){
        if(name.length < minLength){
            return false;
        }
        return true;
    }
}