import axios from "axios";
import {
    FETCH_DICTIONARY_ACTION, SET_DICTIONARY_MUTATION,
} from "@/store/storeconstants";

export default {
    async [FETCH_DICTIONARY_ACTION](context,payload){
        if(context.state.dictionary[payload]){
            return  context.state.dictionary[payload];
        }else {
            return await axios.get('forms/dict/' + payload).then(res => {
                context.commit(SET_DICTIONARY_MUTATION,res.data);
                return res.data;
            }).catch(err => {
                console.log(err);
                return [];
            });
        }
    },
};
