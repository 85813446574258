<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>
<script>
  import EmptyLayout from '@/views/layout/EmptyLayout'
  import MainLayout from '@/views/layout/MainLayout'
  import { mapState } from 'vuex';
  import {AUTO_LOGIN_ACTION} from "@/store/storeconstants";
  import axios from '@/services/axios'

  export default {
    components: {
      EmptyLayout,
      MainLayout
    },
    computed: {
      layout () {
        return (this.$route.meta.layout || 'empty') + '-layout'
      },
      ...mapState({
        autoLogout: (state) => state.auth.autoLogout,
      }),
    },
    watch:{
      autoLogout(curValue, oldValue){
        if( curValue && curValue != oldValue ){
          this.$router.replace('/login');
        }
      }
    },
    created() {
      this.$store.dispatch(`auth/${AUTO_LOGIN_ACTION}`)
    }
  }
</script>

<style lang="scss">
  #app {
    /*  -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;*/
  }
</style>
