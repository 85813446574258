import axios from "axios";
import {
    ADD_PRODUCT_ACTION,
    DELETE_PRODUCT_ACTION, FETCH_ITEM_PRODUCT_ACTION,
    FETCH_PRODUCTS_ACTION,
    SET_PRODUCTS_MUTATION, UPDATE_PRODUCT_ACTION, UPDATE_ROLE_ACTION
} from "@/store/storeconstants";

export default {
     async [FETCH_PRODUCTS_ACTION](context){
       let response = '';
         try{
             response = await axios.get('products');
             context.commit(SET_PRODUCTS_MUTATION,response.data);
             return response;
         }catch (err) {
             console.log(err);
             /*TODO: err handler*/
         }
     },

    async [FETCH_ITEM_PRODUCT_ACTION](context, payload){
        return await axios.get('products/'+payload).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        });
    },

    async [ADD_PRODUCT_ACTION](context,payload){
        return axios.post('products',payload).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    },

    async [UPDATE_PRODUCT_ACTION](context,payload){
        return axios.put(`products/${payload.id}`,payload.data).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },

    async [DELETE_PRODUCT_ACTION](context, payload){
            return await axios.delete('products/'+payload).then(res => {
                return res.data;
            }).catch(err => {
                return Promise.reject(err.response.data);
            });
    }


};