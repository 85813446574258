import axios from "axios";
import {
    ADD_USER_ACTION,
    ADD_USER_ROLES_ACTION, DELETE_USER_ACTION,
    FETCH_ITEM_USER_INFO,
    GET_USERS_ACTION,
    SET_USERS_MUTATION, UNSET_USER_ROLES_ACTION, UPDATE_USER_ACTION
} from "@/store/storeconstants";

export default {
     async [GET_USERS_ACTION](context){
       let response = '';
         try {
             response = await axios.get('users');
             context.commit(SET_USERS_MUTATION,response.data);
             return response;
         } catch (err) {
             console.log(err);
             /*TODO: err handler*/
         }
     },

     async [ADD_USER_ACTION](context,payload){
         return axios.post('register',payload).then(res => {
             return res;
         }).catch(err => {
             console.log(err)
             return Promise.reject(err.response);
         })
     },

    async [UPDATE_USER_ACTION](context,payload){
        return axios.post(`user/${payload.id}`,payload.record).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },


    async [DELETE_USER_ACTION](context,user_id){
        return axios.delete(`users/${user_id}`).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },

    async [FETCH_ITEM_USER_INFO](context,payload){
        return axios.get('users/'+payload).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },

    async [ADD_USER_ROLES_ACTION](context,payload){
        return axios.post('config/role',payload).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },

    async [UNSET_USER_ROLES_ACTION](context,payload){
        return axios.post('config/role/unset',payload).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    }
};
