export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello!!"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["card"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruction"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generate"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])},
  "add_new_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new record"])},
  "main_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main info"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "save_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save copy"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved!"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
}