import axios from "axios";
import {
    ADD_CATEGORY_ACTION,
    DELETE_CATEGORY_ACTION,
    FETCH_CATEGORIES_ACTION,
    SET_CATEGORIES_MUTATION
} from "@/store/storeconstants";

export default {
     async [FETCH_CATEGORIES_ACTION](context){
       let response = '';
         try{
             response = await axios.get('category');
             context.commit(SET_CATEGORIES_MUTATION,response.data);
             return response;
         }catch (err) {
             console.log(err);
             return [];
             /*TODO: err handler*/
         }
     },

    async [ADD_CATEGORY_ACTION](context,payload){
        return axios.post('category',payload).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    },

    async [DELETE_CATEGORY_ACTION](context, payload){
            return await axios.delete('category/'+payload).then(res => {
                return res.data;
            }).catch(err => {
                return Promise.reject(err.response.data);
            });
    }
};