import {
    AUTH_ACTION,
    AUTO_LOGIN_ACTION, AUTO_LOGOUT_ACTION, FETCH_USER_INFO_ACTION, FETCH_USER_PROFILE_ACTION, FETCH_USER_ROLES_ACTION,
    LOGIN_ACTION,
    LOGOUT_ACTION, SET_AUTO_LOGOUT_MUTATION, SET_USER_INFO_DATA_MUTATION,
    SET_USER_TOKEN_DATA_MUTATION
} from "@/store/storeconstants";
import axios from "axios";
import LoginValidation from "@/services/LoginValidation";

let timer = '';
export default {
    async [LOGIN_ACTION] (context, payload) {
        return context.dispatch(AUTH_ACTION,{
            ...payload,
            url: `login`
        })
    },
    [LOGOUT_ACTION](context){
        context.commit(SET_USER_TOKEN_DATA_MUTATION,{
            email: null,
            token: null,
            expiresIn: null,
            refreshToken: null,
            userId: null
        });
        context.commit(SET_USER_INFO_DATA_MUTATION,{});

        axios.defaults.headers.common = null;

        localStorage.removeItem('clientDM');
        localStorage.removeItem('userInfoDM');
        /*localStorage.removeItem('userInfo');*/
        if (timer) {
            clearTimeout(timer);
        }
    },

    [FETCH_USER_PROFILE_ACTION](context){
        let userInfo = localStorage.getItem('userInfoDM');
        if (userInfo){
            context.commit(SET_USER_INFO_DATA_MUTATION, response.data);
            return JSON.parse(userInfo);
        }else {
            return axios.get(`profile`).then(response => {
                localStorage.setItem('userInfoDM',JSON.stringify(response.data));
                context.commit(SET_USER_INFO_DATA_MUTATION, response.data);
                return response.data;
            })
        }

    },

    [AUTO_LOGIN_ACTION](context){
        let userDataStr = localStorage.getItem('clientDM');
        if(userDataStr){
            let userData = JSON.parse(userDataStr);
            /*TODO: refresh token*/
            /*
            let expirationTime = userData.expiresIn - new Date().getTime();
            if(expirationTime < 900000){
                context.dispatch(AUTO_LOGIN_ACTION);
            }else {
                timer = setTimeout(() => {
                   context.dispatch(AUTO_LOGOUT_ACTION);
                }, expirationTime);
            }*/
            context.commit(SET_USER_TOKEN_DATA_MUTATION, userData);

            /*user info*/
           /* let userInfo = localStorage.getItem('userInfo');
            if (userInfo)
                context.commit(SET_USER_INFO_DATA_MUTATION,JSON.parse(userInfo));*/

        }
    },

    [AUTO_LOGOUT_ACTION](context){
        context.dispatch(LOGOUT_ACTION);
        context.commit(SET_AUTO_LOGOUT_MUTATION);
    },

    async [AUTH_ACTION](context, payload){
        let postData = {
            email: payload.email,
            password: payload.password,
            is_admin: true,
            returnSecureToken: true,
        }
        let response = '';
        console.log(JSON.stringify(postData))
        try{
            response = await axios.post(payload.url,postData)

        }catch (err) {
            let errorMessage = LoginValidation.getErrorMessageFromCode(err.response.data.message);
            throw errorMessage;
        }
        if (response.status === 200){

            let expirationTime = 3000000;

            timer = setTimeout(() => {
                context.dispatch(AUTO_LOGOUT_ACTION);
            }, expirationTime);

            let tokenData = {
                email: payload.email,
                token: response.data.Bearer,
                expiresIn: expirationTime,
                refreshToken: 'test',
                userId: 'test'
            };
            localStorage.setItem('clientDM', JSON.stringify(tokenData));
            context.commit(SET_USER_TOKEN_DATA_MUTATION, tokenData);
            /*save token*/
            axios.defaults.headers.common = {'Authorization': `Bearer ${tokenData.token}`};

            //context.dispatch(FETCH_USER_INFO_ACTION,{ userId: tokenData.userId});
            context.dispatch(FETCH_USER_ROLES_ACTION,{ userId: tokenData.userId});
            context.dispatch(FETCH_USER_PROFILE_ACTION);
        }
    },

    async [FETCH_USER_INFO_ACTION](context, payload){
        let response = '';
        try {
           /* response = await axios.get('users/'+payload.userId);
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            context.commit(SET_USER_INFO_DATA_MUTATION,response.data);*/
        }catch (err) {
            /*TODO: ERROR NOTIFICATION*/
            console.log(err);
        }
    },

    async [FETCH_USER_ROLES_ACTION](context, payload){
        let response = '';

        try{
           /* response = await axios.get('roles/user/'+payload.userId, {"title": true});*/
        }catch (err) {
            console.log(err);
        }
    }

};