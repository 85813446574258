<template>
    <div class="breadcrumbs__parent">

        <template v-for="(mroute, mroute_idx) in this.$route.matched">
            <span @click="this.$router.push({name: mroute.name})"
                    :class=" this.$route.matched.length === (mroute_idx + 1)  ?'breadcrumbs__link-current':''"
                    class="breadcrumbs__link" >{{ mroute.meta.title }}</span>
        </template>

    </div>
</template>

<script>
  export default {
      name: 'Breadcrumbs',
  }
</script>

<style scoped>
    .breadcrumbs__parent{
        display: flex;
        position: relative;
    }
    .breadcrumbs__link{
        text-decoration: none;
        font-size: 1rem;
        font-weight: 400;
        color: #A3A2A3;
        position: relative;
        padding-right: 32px;
        cursor: pointer;

    }
    .breadcrumbs__link-current{
        color: #284892;
    }

    .breadcrumbs__link::after, .breadcrumbs__link::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        right: 11px;
        background-color: #A3A2A3;
    }
    .breadcrumbs__link::after {
        top: 8px;
        transform: rotate(45deg);
    }
    .breadcrumbs__link::before {
        top: 14px;
        transform: rotate(-45deg);
    }
    .breadcrumbs__link-current:after,.breadcrumbs__link-current:before{
        content: none;
    }

    @media screen and (max-width: 600px) {
        .breadcrumbs__parent{
            display: none;
        }
    }
</style>
