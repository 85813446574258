import {
    GET_USER_INFO_GETTER,
    GET_USER_ROLES_GETTER,
    GET_USER_TOKEN_GETTER,
    IS_USER_AUTHENTICATE_GETTER
} from "@/store/storeconstants";
import axios from "axios";

export default {
    [GET_USER_TOKEN_GETTER]: state => {
        return state.token;
    },
    [IS_USER_AUTHENTICATE_GETTER](state){
        axios.defaults.headers.common = {'Authorization': `Bearer ${state.token}`};
        console.log(state.token)
        return !!state.token;
    },
    [GET_USER_INFO_GETTER]: state => {
        return state.userInfo;
    },
    [GET_USER_ROLES_GETTER](state){
        return state.userRoles;
    }
};