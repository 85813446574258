<template>
    <div class="header__right">
        <div class="menu__box" @click="this.$emit('toggleMenuLeft',toggleMenuLeft = !toggleMenuLeft)">
            <burger-menu></burger-menu>
        </div>
        <div class="menu__logo">
            <!--<img :src="require('@/assets/img/logo.svg')">-->
        </div>
        <div class="menu__logo-txt" ref="menuRef">
            {{info?.first_name.substring(0,1).toUpperCase() + info?.last_name.substring(0,1).toUpperCase()}}
            <div class="tooltiptext" ref="tooltip">
                <div style="font-weight: 500; font-size: 16px; line-height: 20px; color: #727173; padding-bottom: 14px; display: flex; justify-content: space-between">
                    <span>{{info?.full_name}}</span>
                    <span>X</span>
                </div>
                <div v-if="info?.card" style="padding-bottom: 4px;" >{{$t('card')}}: <span style="color: #59595A;">{{info?.card}}</span></div>
                <div style="padding-bottom: 4px;">{{$t('email')}}: <span style="color: #1A92E5; font-size: 12px;">{{info?.email}}</span></div>
            </div>
        </div>
        <div class="menu__logout" @click.prevent="onLogout()">
            <img :src="require('@/assets/img/Logout.svg')">
        </div>
        <div class="menu__info">
            <div style="text-align: center; padding: 8px">
                {{$t('instruction')}}
            </div>
            <div style="text-align: center; padding: 8px">
                <img :src="require('@/assets/img/Info-circle-outline.svg')">
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {
    FETCH_USER_PROFILE_ACTION, GET_USER_INFO_GETTER, IS_USER_AUTHENTICATE_GETTER,
    LOGOUT_ACTION,
} from "@/store/storeconstants";
import BurgerMenu from "@/components/common/fields/BurgerMenu";
const currentBurgerState = localStorage.getItem('burgerMenu');

export default {
  name: 'HeaderRight',
    components: {BurgerMenu},
    data(){
      return{
          toggleMenuLeft: currentBurgerState ? JSON.parse(currentBurgerState) : true,
          currentOrganization: null,
          currentRoles: [],
          info: localStorage.getItem('userInfoDM')
      }
    },
    computed: {
     ...mapGetters('auth',{
       isAuthenticated: IS_USER_AUTHENTICATE_GETTER,
       userInfo: GET_USER_INFO_GETTER
     }),

  },
  watch: {
        userInfo(val) {
            if (!val) {
                return;
            }
            if (this.userInfo.organization_id) { 
                this.getDetailOrganization(this.userInfo.organization_id).then(res => {
                    this.currentOrganization = res;
                });
            }
        }
    },
    created() {
        if (this.info === null){
            this.fetchUserInfo().then(res => {
                this.info = res;
            })
        }else this.info = JSON.parse(this.info);
    },
  methods: {
      ...mapActions('auth', {
          logout: LOGOUT_ACTION,
          fetchUserInfo: FETCH_USER_PROFILE_ACTION
      }),
      onLogout(){
          this.logout();
          this.$router.replace('/login');
      }
  }
}
</script>

<style scoped lang="scss">
    .header__right{
        width: 100%;
        height: 100%;
    }
    .menu__box{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-left: 24px;
        top: 50%;
        transform: translateY(-50%);
    }
    .menu__box:hover{
        background:  #E4EBFA;
    }
    .menu__logo{
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-left: 20px;
        top: -35px;
        left: -11px;
    }
    .menu__logo-txt{
        width: 40px;
        height: 40px;
        background-color: #F6F8FD;
        border-radius: 50%;
        z-index: 100;
        border: 4px solid #E4EBFA;
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #A4BBEF;
        cursor: pointer;
    }
    .menu__logo-txt:hover{
        background-color: #284892;
        color: #E4EBFA;
    }
    .menu__logout{
        display: inline-block;
        float: right;
        cursor: pointer;
        padding: 8px;
        position: relative;
        margin-right: 20px;
        top: calc(50% - 2px);
        transform: translateY(-50%);
    }
    .menu__info{
        display: grid;
        grid-template-columns: 1fr 50px;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
                ". .";
        max-width: 178px;
        float: right;
        position: relative;
        top: calc(50% - 2px);
        transform: translateY(-50%);
        cursor: pointer;
    }

    .menu__logo-txt {
        position: relative;
    }

    .menu__logo-txt .tooltiptext {
        visibility: hidden;
        width: 318px;
        height: auto;
        background-color: #ffffff;
        color: #727173;
        text-align: left;
        padding: 16px;
        position: absolute;
        z-index: 300;
        top: 120%;
        right: 0;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        border: 1px solid #3962BB;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .menu__logo-txt .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 8px;
        margin-left: -5px;
        border-width: 8px;
        border-style: solid;
        transform: rotate(180deg);
        border-color: #3962BB transparent transparent transparent;
    }

    .menu__logo-txt:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
</style>
