import axios from "axios";
import {
    ADD_PERMISSION_ACTION, ADD_PERMISSION_CATEGORIES,
    DELETE_PERMISSION_ACTION, FETCH_ITEM_PERMISSION_ACTION,
    FETCH_PERMISSIONS_ACTION,
    SET_PERMISSIONS_MUTATION, UPDATE_PERMISSION_ACTION,
} from "@/store/storeconstants";

export default {
     async [FETCH_PERMISSIONS_ACTION](context){
       let response = '';
         try {
             response = await axios.get('dictionary/permissions');
             context.commit(SET_PERMISSIONS_MUTATION,response.data);
             return response;
         } catch (err) {
             console.log(err);
             /*TODO: err handler*/
         }
     },

    async [FETCH_ITEM_PERMISSION_ACTION](context, payload){
        return await axios.get('permission/'+payload).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        });
    },

    async [ADD_PERMISSION_ACTION](context,payload){
        return axios.post('permission',payload).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    },

    async [UPDATE_PERMISSION_ACTION](context,payload){
        return axios.put(`permission/${payload.id}`,payload).then(res => {
            return res;
        }).catch(err => {
            console.log(err)
            return Promise.reject(err.response);
        })
    },

    async [ADD_PERMISSION_CATEGORIES](context,payload){
        return axios.post('config/permission',{model: 'Category', model_id: payload.category_id, permission_id: payload.permission_id}).then(res => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err.response.data);
        })
    },

    async [DELETE_PERMISSION_ACTION](context, payload){
            return await axios.delete('permission/'+payload).then(res => {
                return res.data;
            }).catch(err => {
                return Promise.reject(err.response.data);
            });
    }
};
