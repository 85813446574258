import {
    SET_FORM_CONFIG_MUTATION,
    SET_FORM_PARAMS_MUTATION,
    SET_FORM_RESULTS_MUTATION,
    SET_FORM_ROWS_MUTATION
} from "@/store/storeconstants";

export default {
    [SET_FORM_PARAMS_MUTATION] (state, payload){
        state.params = payload;
    },

    [SET_FORM_RESULTS_MUTATION] (state, payload){
        state.results = payload;
    },

    [SET_FORM_CONFIG_MUTATION] (state, payload){
        state.config = payload;
    }
};
