<template>
    <div style="height: 105px; text-align: center;">
        <div>
            <img class="logo__kap" src="@/assets/img/eKAP.png">
        </div>
<!--        <div style="font-size: 18px; font-weight: 500; color: white;">
            Идет загрузка…
        </div>-->
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>
    .logo__kap{
        width: 80px;
        height: 80px;
        animation-name: scaleme;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    @keyframes scaleme {
        0%   {
            -webkit-transform : rotate(0deg) scale(1); /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform     : rotate(0deg) scale(1); /* IE 9 */
            transform         : rotate(0deg) scale(1); /* Firefox 16+, IE 10+, Opera */
        }
        25%  {
            -webkit-transform : rotate(90deg) scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform     : rotate(90deg) scale(1.2); /* IE 9 */
            transform         : rotate(90deg) scale(1.2); /* Firefox 16+, IE 10+, Opera */
        }
        50%  {
            -webkit-transform : rotate(180deg) scale(1.4); /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform     : rotate(180deg) scale(1.4); /* IE 9 */
            transform         : rotate(180deg) scale(1.4); /* Firefox 16+, IE 10+, Opera */
        }
        75%  {
            -webkit-transform : rotate(270deg) scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform     : rotate(270deg) scale(1.2); /* IE 9 */
            transform         : rotate(270deg) scale(1.2); /* Firefox 16+, IE 10+, Opera */
        }
        100% {
            -webkit-transform : rotate(360deg) scale(1); /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform     : rotate(360deg) scale(1); /* IE 9 */
            transform         : rotate(360deg) scale(1); /* Firefox 16+, IE 10+, Opera */
        }
    }

</style>